<template>
  <div class="Box">
    <slideshow :list="slideList" />
    <div class="textBox">
      <div class="textLogo"></div>
      <div class="headline">
        <textTitle text="瑜璟缘国际文化礼仪介绍" />
        <div class="article">
          <div
            class="text"
          >&#160; 北京瑜璟缘国际文化发展有限公司是一所集形象管理培训（设计）、礼仪文化培训及策划运营管理为一体的专注于孕育及培养提升人才素养的专业培训机构。在全国拥有近200人的专业的签约礼仪咨询顾问和专家队伍，专注于为企业提供系统礼仪管理咨询与业务解决方案，为企业搭建高素质人才培养平台，为企业形象及人素养教育提升开辟有效途径，帮助企业完善礼仪文化建设，是国内多家企事业单位背后最有效的形象生产及教育培训基地。公司以“不学礼，无以立。”的宗旨，秉承“无问题，不培训”的运营理念，致力于打造新经济下交流分享、提升自我，创新升级，共享共赢生态圈的高端商业精英交流平台。专注于为企业家、创业者、企业高管提供创新升级的商业课程，高效落地的企业内训，垂直定向的主题沙龙，拓展视野的海外游学以及资源整合等培训咨询共享共赢服务解决方案。</div>
          <img src="../../assets/img/home/textImg.png" />
        </div>
      </div>
    </div>
    <div class="advisoryBox">
      <div class="left">
        <a
          href="http://wpa.qq.com/msgrd?v=3&uin=30397378&site=qq&menu=yes"
          target="view_window"
          class="iconBox"
        >
          <div class="icon icon1"></div>
          <div class="text">免费质询</div>
        </a>
        <div class="iconBox" @click="go('famousTeacher?tag=zj')">
          <div class="iconLeft icon2"></div>
          <div class="textLeft">授课名师</div>
        </div>
        <div class="iconBox" @click="go('course')">
          <div class="icon icon3"></div>
          <div class="text">热门课程</div>
        </div>
        <div class="iconBox" @click="go('excellent')">
          <div class="iconLeft icon4"></div>
          <div class="textLeft">企业培训优秀案例</div>
        </div>
        <div class="partnerBox">
          <div class="title">合作伙伴</div>
          <div class="partner" v-for="(item, index) in partnersList" :key="index">
              <partnerBox :url="item.mainPicRealPath" :link="item.linkUrl" />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="journalismTitle">
          <p>新闻中心</p>
          <span @click="moreNews">MORE</span>
        </div>

        <div class="journalismBox" v-for="(item, index) in newsList" :key="index" @click="goparticulars(item)">
          <img class="logo" :src="item.mainPicRealPath" alt />
          <div class="journalismText">
            <h3>{{item.title}}</h3>
            <span class="journalismText-content" v-html="item.content"></span>
            <div class="time">{{item.publishDate}} <span class="el-icon-view"> {{item.view}}</span></div>
          </div>
        </div>

        <div class="attention">IBIE全球杂志</div>
        <consult />
      </div>
    </div>
  </div>
</template>
<script>
import slideshow from "@/components/slideshow/slideshow";
import consult from "@/components/consult/consult";
import textTitle from "@/components/headTail/textTitle";
import partnerBox from "@/components/partner/partnerBox";
import { homeService } from "@/api"
import moment from "moment"
export default {
  components: {
    slideshow,
    consult,
    textTitle,
    partnerBox,
  },
  data() {
    return {
      slideList: [],
      partnersList: [],
      newsList: []
    };
  },
  methods: {
    /* 轮播图 */
    getSlideList() {
      let data = {
         pageNum: 1,
         pageSize: 100
      }
      homeService.getBannerList(data).then(res => {
        this.slideList = res.list
      })
    },
    /* 左侧合作伙伴 */
    getPartnersList() {
      let data = {
        pageNum: 1,
        pageSize: 5
      }
      homeService.getPartnersList(data).then(res => {
        this.partnersList = res.list
      })
    },
    /* 新闻列表 */
    getNewsList() {
      let data = {
        type: 1,
        pageNum: 1,
        pageSize: 3
      }
      homeService.getNewsList(data).then(res => {
        res.list.forEach(ele => {
            ele.publishDate = moment(ele.publishDate).format("YYYY-MM-DD")
            ele.content = ele.content.replace(/<img\b[^>]*>/, "");  
        });
        this.newsList = res.list
      })
    },
    moreNews() {
      this.$router.push('/journalism');
    },
    goparticulars(item) {
      let detailId= item.id + ".html"
      this.$router.push({name: "particulars",params:{ id: detailId, ifjournalism: 2}})
    },
    go(path) {
      this.$router.push(`/${path}`);
    },
  },
  created() {
    this.getSlideList()
    this.getPartnersList()
    this.getNewsList()
  }
};
</script>

<style lang="scss" scoped>
.Box {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .textBox {
    margin: 10px 0;
    height: 386px;
    border: 1px rgb(238, 238, 238) solid;
    position: relative;
    .textLogo {
      height: 70px;
      width: 70px;
      // background-color: cornsilk;
      background-image: url("../../assets/img/home/angleTop.png");
      top: 0;
      left: 0;
      position: absolute;
    }
    .headline {
      color: #1e1f4c;
      margin-left: 150px;

      .article {
        overflow: hidden;
        .text {
          float: left;
          width: 700px;
          font-size: 14px;
          line-height: 25px;
        }
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          background-color: cyan;
          float: right;
          margin-right: 50px;
        }
      }
    }
  }
  .advisoryBox {
    overflow: hidden;
    .left {
      float: left;
      width: 26%;
      border-top: 1px rgb(238, 238, 238) solid;
      .iconBox {
        overflow: hidden;
        margin: 10px 0;
        position: relative;
        height: 80px;
        display: block;
        color: #000;
        cursor: pointer;
        .icon {
          width: 75px;
          height: 75px;
          background-color: #999999;
          border-radius: 50%;
          position: absolute;
          left: 20px;
          top: 5px;
        }
        .icon1 {
          background-image: url("../../assets/img/home/icon-1.png");
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center center;
        }
        .icon2 {
          background-image: url("../../assets/img/home/icon-2.png");
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center center;
        }
        .icon3 {
          background-image: url("../../assets/img/home/icon-3.png");
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center center;
        }
        .icon4 {
          background-image: url("../../assets/img/home/icon-4.png");
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center center;
        }
        .text {
          width: 230px;
          height: 45px;
          background-color: #eeeeee;
          border-radius: 50px;
          line-height: 45px;
          margin-top: 20px;
          margin-left: 20px;
          text-indent: 100px;
          border: #cccccc 1px solid;
          font-size: 14px;
        }
        .iconLeft {
          width: 75px;
          height: 75px;
          background-color: #999999;
          border-radius: 50%;
          position: absolute;
          left: 180px;
          top: 5px;
        }
        .textLeft {
          width: 230px;
          height: 45px;
          background-color: #eeeeee;
          border-radius: 50px;
          line-height: 45px;
          margin-top: 20px;
          margin-left: 20px;
          text-indent: 40px;
          border: #cccccc 1px solid;
          font-size: 14px;
        }
      }
      .partnerBox {
        width: 80%;
        overflow: hidden;
        .title {
          font-size: 14px;
          background-color: #eee;
          font-weight: bolder;
          height: 40px;
          text-indent: 50px;
          line-height: 40px;
          
        }
        .partner{
          width: 80%;
          margin: 0 10%;
        }
        
      }
    }
    .right {
      width: 70%;
      float: right;
      overflow: hidden;
      .journalismTitle {
        margin: 10px 0;
        background-color: rgb(232, 232, 232);
        overflow: hidden;
        font-size: 14px;

        p {
          width: 200px;
          height: 45px;
          line-height: 45px;
          text-indent: 30px;
          float: left;
        }
        span {
          height: 45px;
          line-height: 45px;
          text-indent: 30px;
          float: right;
          margin-right: 20px;
          cursor: pointer;
        }
      }
      .journalismBox {
        overflow: hidden;
        border-bottom: rgb(232, 232, 232) 1px solid;
        margin: 5px 0;
        cursor: pointer;
        .logo {
          background-color: rosybrown;
          height: 110px;
          width: 140px;
          float: left;
          margin-bottom: 10px;
          margin-right: 20px;
          background-image: url(/img/on1.688f88b1.png);
          margin-top: 10px;
        }
        .journalismText {
          float: left;
          width: 660px;
          h3 {
            width: 90%;
            margin: 5px 0;
            font-size: 16px;
          }
          span.journalismText-content {
            min-height: 60px;
            overflow: hidden;
            line-height: 19px;
            color: #999;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-size: 14px;
          }
          .time {
            line-height: 19px;
            color: rgb(214, 214, 206);
            padding: 10px 0;
            span{
              float: right;
            }
          }
        }
      }
      .attention {
        margin: 20px 0;
        background-color: rgb(153, 153, 153);
        overflow: hidden;
        font-size: 14px;
        height: 45px;
        line-height: 45px;
        text-indent: 30px;
        color: rgb(241, 255, 255);
      }
    }
  }
}
</style>
<style  scoped>
.journalismText span >>> span{
  font-size: 14px !important;
}
</style>
